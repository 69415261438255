/* $menuWidth: 250px;
$smallMenuWidth: 150px;
$small: 700px; */
$gutter: 1.5rem;
// $halfGutter: math.div($gutter, 2);
$mainColor: #1976d2;
$mainLightGrey: #cfd8dc;
//#cddc39
$mainDark: #071E3D;
$mainFontAnthra: #011736;

$mainBackground: white;
$cancelOption: crimson;

//DARK MODE
$mainColorDark: #01a7a4;
$secondaryColorDark: #2cbacf;
$mainFontAnthraDark: #fff;
$mainFontSecondaryDark: #C9D6E1;
// $mainFontGrey: #d3d3d3;
$mainBackgroundDark: #192B3C;
$mainBackgroundformDark: #21374A;
$mainBackgroundClearDark: #c5d2dd;
$cancelOptionDark: #B91A43;
$cancelOptionDark: rgb(209, 56, 87);

$color-light: #cfd8dc;
$color-alt2:#0d7e22;

//green gray theme
$darkGrey:#102027;
$lightGrey:#ececec;
$middleGrey:#37474f;

$darkGreen:#79b700;
$lightGreen:#f1fdaa;
$middleGreen:#aeea00;
