@use '../../styles/vars';

.contact {
  transition: 0.2s;
  background-color: vars.$darkGrey;;
  width: 90%;
  margin: auto;
  padding: 1rem;
  height: max-content;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width:400px) {
    width: 100%;
  }
  
  &__message {
    padding: 0.5rem;
    width: 70%;
    align-self: center;
    display: flex;

    @media (max-width:800px) {
      width: 85%;
    }
    @media (max-width:400px) {
      width: 100%;
    }
    
    //text area
    &__field {
      background-color: rgb(240, 236, 236);
      border-radius: 1rem;
      border: vars.$darkGrey;
      width: 100%;
      padding: 0;
      margin: auto;

      :focus {
        background-color:#fff;
      }
      &:hover {
        background-color: vars.$lightGreen;;
      }
    }

    &--confirm {
      width: 70%;
      margin: auto;
      padding: 1rem;
       
      &--alert {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.light {
  transition: 0.2s;
  color: vars.$darkGrey;
  background-color: vars.$lightGrey;
}

.hide {
  display: none;
}

#submit {
  margin: auto;
  width: 30%;
  border-radius: 1rem;
  color: vars.$darkGrey;
  background-color: vars.$middleGreen;
  font-weight: bolder;
  font-size: 1.1rem;

  @media (max-width:800px) {
    width: 50%;
    min-width: 85px;
  }
  &:hover {
    zoom: 120%;
  }
}