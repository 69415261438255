@use '../../styles/vars';

.skills {
  width: 90%;
  margin: auto;
  height: auto;
  background-color: vars.$darkGrey;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  //align-items: center;

  &__icons {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1%;
    font-weight: bold;
    font-size: 1.3rem;

    @media (max-width: 800px)
    {
      display: flex;
      align-items: center;
    }

    &--icon {
    flex-direction: column;
    width: 7rem;
    padding: 0.5rem;
    color: vars.$lightGrey;
    
      &:hover {
        background-color: vars.$middleGreen;;
        color: vars.$darkGrey;
        zoom: 105%;
        }
    }
  }
}

img {
  width: 4rem;
  height: 4rem;

   @media (max-width : 800px) {
    width: 3.5rem;
    height: 3.5rem;
   }

   @media (max-width:400px) {
    width: 3rem;
    height: 3rem;
  }
}

p {
  margin: 2px 0 ;
  font-size: max(1.1rem, 1vw);
}

.light {
  color: vars.$darkGrey;
  background-color: vars.$lightGrey;
}
