@use '../../styles/vars';

.projects {
  width: 90%;
  margin: auto;
  height: max-content;
  display: flex;
  flex-direction: column;
  background-color: vars.$darkGrey;

  @media (max-width: 800px)
    {
      display: flex;
    }

    &__title {
      @media (max-width:800px) {
        justify-content: flex-start;
      }
    }
  
  &__project {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 87%;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    padding: 1%;
    
    @media (max-width: 800px)
    {
      margin: auto;
      flex-direction: column;
    }

    &--img {
      margin: 1rem;
      height: 9rem;
      width: 8rem;

      @media (max-width: 800px)
      {
      margin: 0;
      display: flex;
      padding: 8px;
      align-items: center;
      }
    }
    &--h3 {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
      text-decoration: underline;
      text-transform: uppercase;
    }
    &--p {
      line-height: 1.2rem;
      margin: 0.8rem;
    }
    &__box {
      margin: 0.5rem;
      width: 47%;
      padding: 0.5rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (min-width: 1200px)
        {
        max-width: 40%;
        }
        @media (max-width: 800px)
        {
        width: 70%;
        align-self: center;
        }
        @media (max-width: 520px)
        {
        width: 90%;
        align-self: center;
        }

      &--link {
        color: vars.$darkGrey;
        font-weight: bold;
        font-size: max(0.9rem, 1vw);
        width: 100%;
        padding: 10px;
        word-wrap: break-word;

        &:hover {
          zoom: 120%;  
        }
      }
    }
  }
}

p {
  padding-bottom: 0;
}

.light {
  color: vars.$darkGrey;
  background-color: vars.$lightGrey;
}
