@use '../../styles/vars';

.me {
  width: 90%;
  height: 33rem;
  color: vars.$lightGrey;
  margin: auto;
  margin-top: 3*vars.$gutter;
  display: flex;
  align-items: center;
  
    @media (max-width: 800px){
    flex-direction: column;
    height: min-content;
    margin-top: 1rem;
    }

  &__desc {
    padding: 2px;
    width: 70%;
    background-color: vars.$darkGrey;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    
      &--name {
        font-size: 3rem;
        font-weight: bold;
        margin-top: 15px;
        color:vars.$middleGreen;
        display: flex;

        @media (max-width:800px) {
          width: 60%;
          font-size: 2.3rem;
          justify-content: center;
        }
        @media (max-width:400px) {
          font-size: 1.8rem;
          margin-top: 1rem;
        }
      }

      &--pic {
        object-fit:cover;
        width: 280px;
        height: 320px;
        text-align: center;
        margin: 2% 0;
      }

      &--title {
        font-size: 1.7rem;
        
      }
    
    @media (max-width: 800px) {
      width: 100%;
      height: min-content;
      
      &--pic {
        object-fit:cover;
        width: 200px;
        height: 250px;
        text-align: center;
        margin: 2% 0;
      }

    }
  }

  &--about {
    background-image: url("../../assets/dev_web.jpg") ;
    background-size: cover;
    height: 100%;
    width: 100%;  
    
    @media (max-width: 800px) {
      width: 100%;
      height: 20rem;
      text-align: center;
    }

    &.light {
      filter:invert(1);   
    }

    &--title  {
      width: 50%;
      margin: 4rem;
      height: 0;
      text-shadow: 5px 5px 10px black, 0 0 1em black, 0 0 0.2em black;
      text-align: left;
      font-weight: bolder;
      font-size: 3rem;
      text-transform: uppercase;
      color:white;
 
      @media (max-width:800px) {
        margin: 3rem;
        font-size: 2.2rem;
      }

      @media (max-width:400px) {
        margin: 1rem;
        font-size: 1.5rem;
      }

      &.light {
        color: vars.$darkGrey;
        background-color: transparent;
      }
      & p {
        margin-top: 1rem;
        font-size: 1.8rem;
        @media (max-width:800px) {
          font-size: 1.4rem;
        }
        @media (max-width:400px) {
          font-size: 1.2rem;
        }
      }
    }
    
    &--button {
      bottom: 60px;
      text-align: right;
      font-weight: bolder;
      text-shadow: 5px 5px 10px black, 0 0 1em black, 0 0 0.2em black;

        &.light {
          color: vars.$darkGrey;
          text-shadow: 5px 5px 10px white, 0 0 1em white, 0 0 0.2em white;
        }
        &:hover {
          zoom: 110%;
        }
    }
    //modal
    &--resume {
      padding: 5%;
      color: vars.$darkGrey;
      background-color: white;
      line-height: 2rem;
      
      &.light {
        background-color: black;
        color: vars.$lightGrey;
      }
      @media (max-width: 800px) {
        background-color: white;
      }
    }
  }
}

#button {
display: flex;
width: 90%;
height: 1rem;
justify-content:flex-end;

  
&.light {
    color: vars.$darkGrey;
    background-color: vars.$lightGrey;
  }
}

.modal_box {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-image: none;
  width: 60%;
  
  @media (max-width: 800px) {
    width: 80%;
    padding: 20px;
  }
  
  &--close {  
      align-self: flex-end;
  }
}

#modal-modal-title {  
  font-size: 2rem;
  font-weight: bold;
  color: vars.$darkGrey;
  @media (max-width:800px){
    font-size: 1.5rem ;
  }
}
#modal-modal-description{

  @media (max-width: 800px) {
    overflow-y: scroll;
    height: 25rem;
  }
}

.light {
  color: vars.$darkGrey;
  background-color: vars.$lightGrey;
}
a {
  color: blue;
}
