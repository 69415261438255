@use './styles/vars';
.app {
  text-align: center;
  font-family: "Montserrat","Roboto","Helvetica","Arial",sans-serif;
  width: 98%;
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: vars.$middleGrey;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  //color: rgb(112, 76, 182);
  
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

body{
  background-color: vars.$darkGrey;
}
.home {
  position: fixed;
  bottom: 0.5rem;
  right:1.5rem;
  border-radius: 20%;
  background-color: vars.$darkGrey;

  &--icon{
    margin: 0.2rem;
    transform: rotate(270deg);
  }
}

.divider {
  height: 1rem;
  background-color: vars.$middleGrey;
}

h2 {
  color:vars.$middleGreen;
  font-size: 2.5rem;
  text-transform: uppercase;
  text-align: left;
  font-weight: bold;
  margin: 1rem 0 0 1rem;

  @media (max-width:400px) {
    font-size: 2rem;
  }
}