@use '../../styles/vars';
#header{
  width: 98%;
  margin: auto;
  left : 0;
  transition: 0.2s;
  font-size: 1.5rem;
  background-color: vars.$middleGreen;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid grey;
  
  @media (max-width: 800px) {
    left: 0px;
    margin: 0;
    opacity: 80%;
    text-align: left;
    width: max-content;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    
    &.header--open {
      left:-185px;
      padding-right: 1rem;
    }
    text-align: left;
    
    } 
}

.header {
  &__themeSwitch {
    display:flex;
    margin-left: 1rem;
    background-color: vars.$lightGrey;
    border-radius: 2rem;
    
    &.light {
      background-color: vars.$middleGreen;
    }
    @media (max-width: 800px) {
      position: fixed;
      top:35px;
      right: 3.2rem;
      }

      @media (max-width:500px) {
        right: 2rem;
      }
  }
  &__nav {
    width: 100%;
    display:flex;
    align-items: center;

      @media (max-width: 800px) {
        flex-direction: column;
        margin-top: 4rem;
        margin-right: auto;
        margin-left: 1rem;
        align-items: flex-start;
        height: min-content;
        width: 11rem;
        }

    &--buttons {
      @media (max-width:800px){
        align-self:flex-end;
        margin-bottom: vars.$gutter;
      }
    }
 
    &--link{
      color: vars.$darkGrey;
      text-transform: uppercase;
      width: 100%;
      
      @media (max-width:800px){
        display: none;
      }
    }

    &--link--mobile {
      display: none;
      
      @media (max-width:800px){
        display: block;
        color: vars.$darkGrey;
        font-weight: bold;
        text-transform: uppercase;
        padding: 2rem 0;
        transition-timing-function: ease-in;
        /* Quick on the way out */
        transition: 0.2s;

        &.light {
          color: vars.$darkGrey;
        }
      }
    }
  }
  
}

.light {
  background-color: vars.$lightGrey;
  color: rgb(0, 0, 0);
}

//button menu mobile
.toggle {
  display: none;
  @media (max-width:800px){
    position: fixed;
    top:30px;
    left: -0.8rem;
    margin-left: 5%;
    display: block;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 3px solid black;
    background-color: white;
    font-size: 1.5em;
    color: vars.$darkGrey;

    &.light {
      background-color: vars.$middleGreen;
      color: vars.$darkGrey;
    }
  }
  
  // au hover on inverse les couleurs
  &:hover {
    border: 3px solid vars.$darkGreen;
    background-color: vars.$darkGrey;
    color:vars.$lightGreen;
  }

}
.MuiSwitch-root {
  display: flex;
  color: red;
}
#labelThemeSwitch {
  margin: 0;
}