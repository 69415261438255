@use '../../styles/vars';

.timeline{
  transition: 0.2s;
  text-align: center;
  justify-content: center;
  width: 90%;
  margin: auto;
  color: vars.$lightGrey;
  background-color: vars.$darkGrey;

  &__item {
    transition : all 0.2s ease 0s;
    //position: relative;
    
    animation: 0.6s ease 0s 1 normal none running cd-bounce-2;
   // animation: cd-bounce-2-inverse .6s;
    //transform: translateX(-100px);
    //transform: translateX(20px)
  }

}

.light {
  transition: 0.2s;
  color: vars.$darkGrey;
  background-color: vars.$lightGrey;
}